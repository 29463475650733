import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { Api } from 'BootQuery/Assets/js/api';

import { PresenceType } from '../types';

export async function listPresenceTypes(): Promise<PresenceType[]> {
  const { data } = await Api.get<PresenceType[]>('/api/presenceTypes');

  return data;
}

export function usePresenceTypes(): UseQueryResult<PresenceType[]> {
  return useQuery({
    queryKey: ['User.presenceTypes'],
    queryFn: listPresenceTypes,
  });
}

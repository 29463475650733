import { ReactElement, useMemo } from 'react';
import {
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaCaretDown } from 'react-icons/fa';

import { AvailabilityIcon } from './AvailabilityIcon';
import { ColorModeMenu } from './ColorModeMenu';
import { PresenceMenu } from './PresenceMenu';
import { getUserModuleData } from './user-module-data';
import { userStore } from './user-store';

export const UserMenu = (): ReactElement => {
  const { t } = useTranslation('User');

  const presence = userStore((state) => state.presence);
  const userModule = useMemo(getUserModuleData, []);
  const menuModule = useMemo(getMenuModuleData, []);

  return (
    <Menu>
      <MenuButton
        pl="4"
        py="2"
        color="brand.fontStaticLight"
        _hover={{ color: 'brand.lightGray' }}
      >
        <AvailabilityIcon availability={presence?.presence?.type ?? null} />
        &nbsp;
        {userModule.username}&nbsp;
        <FaCaretDown style={{ display: 'inline' }} />
      </MenuButton>
      <MenuList>
        <HStack px="2">
          {/* Wrapped in boxes to avoid popper margin warnings */}
          <Box>
            <PresenceMenu />
          </Box>
          <Box>
            <ColorModeMenu />
          </Box>
        </HStack>
        <MenuDivider />
        {menuModule.menus.header.User.items.map((item) => (
          <MenuItem
            key={item.order}
            as="a"
            href={`/${item.entry.controller}/${item.entry.method}`}
            _hover={{ textDecoration: 'none' }}
          >
            <span className={item.icon}></span>&nbsp;
            {t(`${item.module}:menu_entries.${item.name}`)}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

function getMenuModuleData(): MenuModuleData {
  return window.Bootstrap.modules.Menu as unknown as MenuModuleData;
}

interface IMenuItem {
  name: string;
  icon: string;
  order: number;
  entry: {
    controller: string;
    method: string;
  };
  module: string;
}

interface MenuModuleData {
  menus: {
    header: {
      User: {
        items: IMenuItem[];
      };
    };
  };
}

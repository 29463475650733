import { forwardRef, ReactElement } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

import { AvailabilityIcon } from '../AvailabilityIcon';
import { UserPresence } from '../types';
import { useTranslatePresenceName } from './use-translate-presence-name';

interface Props extends ButtonProps {
  presence: UserPresence | null;
}

export const CurrentPresence = forwardRef((props: Props, ref): ReactElement => {
  const { presence, ...btnProps } = props;

  const translateName = useTranslatePresenceName();
  const mutedColor = useColorModeValue('blackAlpha.500', 'whiteAlpha.500');

  const presenceName = presence?.presence
    ? translateName(presence.presence.name, presence.presence.isSystem)
    : null;

  return (
    <Button ref={ref} justifyContent="start" overflow="hidden" {...btnProps}>
      <AvailabilityIcon availability={presence?.presence?.type ?? null} />
      &nbsp;
      <VStack w="full" pl="1" spacing={0} align="start">
        <Box fontSize={presence?.note ? 'sm' : 'md'} whiteSpace="nowrap">
          {presenceName ?? 'Unknown'}
        </Box>
        {presence?.note && (
          <Box
            fontSize="xs"
            color={mutedColor}
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {presence.note}
          </Box>
        )}
      </VStack>
    </Button>
  );
});

CurrentPresence.displayName = 'CurrentPresence';

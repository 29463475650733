import { ReactElement, useCallback } from 'react';
import {
  Input,
  InputGroup,
  InputRightElement,
  useMenuContext,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { FaCheck } from 'react-icons/fa6';

import { IconButton } from '@bq/components/IconButton';
import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';

import { userStore } from '../user-store';

interface INoteForm {
  note: string;
}

export const NoteInput = (): ReactElement => {
  const { onClose: closeMenu } = useMenuContext();

  const { updatePresence, currentNote } = userStore((store) => ({
    updatePresence: store.updatePresence,
    currentNote: store.presence?.note,
  }));

  const { handleSubmit, register, reset, getValues } = useForm<INoteForm>({
    defaultValues: {
      note: currentNote,
    },
  });

  // If note is updated through events we need to update the form
  useChangeEffect(currentNote, () => {
    if (currentNote !== getValues().note) {
      reset({ note: currentNote });
    }
  });

  const saveNote = useCallback(
    ({ note }: INoteForm) => {
      updatePresence({ note: note || null });
      closeMenu();
    },
    [updatePresence, closeMenu]
  );

  return (
    <form data-ignore-form onSubmit={handleSubmit(saveNote)}>
      <InputGroup size="sm">
        <Input {...register('note')} placeholder="Type a status note..." />
        <InputRightElement>
          <IconButton
            variant="ghost"
            size="sm"
            colorScheme="green"
            icon={<FaCheck />}
            label="Save"
            type="submit"
          />
        </InputRightElement>
      </InputGroup>
    </form>
  );
};

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type TranslatePresenceName = (name: string, isSystem: boolean) => string;

export function useTranslatePresenceName(): TranslatePresenceName {
  const { t } = useTranslation('User');

  return useCallback(
    (name: string, isSystem: boolean) => {
      if (!isSystem) {
        return name;
      }

      switch (name) {
        case 'available':
          return t('User:presence_types.available');
        case 'away':
          return t('User:presence_types.away');
        case 'busy':
          return t('User:presence_types.busy');
        case 'busy_in_call':
          return t('User:presence_types.busy_in_call');
        case 'pause':
          return t('User:presence_types.pause');
        default:
          console.warn('Unknown system presence type: ', name);

          return name;
      }
    },
    [t]
  );
}

import { Api } from 'BootQuery/Assets/js/api';

import { SetPresence, UserPresence } from '../types';

export async function updatePresence(
  presence: SetPresence
): Promise<UserPresence> {
  const { data } = await Api.patch<UserPresence>('/api/me/presence', presence);

  return data;
}

import { throttle } from 'lodash-es';

import { Api } from './api';

export function bindUserActivityHandler() {
  const handleUserActivity = throttle(onUserActivity, 1_000);
  document.addEventListener('click', handleUserActivity);
  document.addEventListener('keydown', handleUserActivity);
  document.addEventListener('navigate', handleUserActivity);
  document.addEventListener('mousemove', handleUserActivity);
  document.addEventListener('focus', handleUserActivity);
  document.addEventListener('blur', handleUserActivity);
}

let lastPath: string | null = null;
let lastActivityAt: Date | null = null;

function onUserActivity() {
  // Only send activity info when logged in
  if (!window.Bootstrap?.bootquery?.session?.userID) {
    return;
  }

  const pathChanged = window.location.pathname !== lastPath;
  const elapsedSinceLastActivity = lastActivityAt
    ? new Date().getTime() - lastActivityAt.getTime()
    : 0;

  if (elapsedSinceLastActivity > 60_000 || pathChanged) {
    lastPath = window.location.pathname;
    lastActivityAt = new Date();

    Api.post('/api/userActivity', {
      url: window.location.toString(),
    });
  }
}

import { ReactElement, useCallback } from 'react';
import { MenuItem, Text } from '@chakra-ui/react';

import { AvailabilityIcon } from '../AvailabilityIcon';
import { PresenceType } from '../types';
import { userStore } from '../user-store';
import { useTranslatePresenceName } from './use-translate-presence-name';

type Props = PresenceType;

export const PresenceTypeItem = ({
  ID,
  type,
  name,
  isSystem,
}: Props): ReactElement => {
  const { updatePresence } = userStore();
  const translateName = useTranslatePresenceName();

  const select = useCallback(() => {
    updatePresence({ presenceID: ID });
  }, [updatePresence, ID]);

  return (
    <MenuItem justifyContent="start" onClick={select}>
      <AvailabilityIcon availability={type} />
      &nbsp;&nbsp;
      <Text as="span" whiteSpace="nowrap">
        {translateName(name, isSystem)}
      </Text>
    </MenuItem>
  );
};

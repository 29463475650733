import { ReactElement } from 'react';
import { Text } from '@chakra-ui/react';
import {
  FaCircle,
  FaCircleCheck,
  FaCircleMinus,
  FaCirclePause,
  FaClock,
} from 'react-icons/fa6';

import { PresenceAvailability } from './types';

interface Props {
  availability: PresenceAvailability | null;
}

export const AvailabilityIcon = ({ availability }: Props): ReactElement => {
  switch (availability) {
    case 'available':
      return (
        <Text as="span" color="green.400">
          <FaCircleCheck style={{ display: 'inline' }} />
        </Text>
      );
    case 'busy':
      return (
        <Text as="span" color="red.400">
          <FaCircleMinus style={{ display: 'inline' }} />
        </Text>
      );
    case 'away':
      return (
        <Text as="span" color="yellow.400">
          <FaClock style={{ display: 'inline' }} />
        </Text>
      );
    case 'pause':
      return (
        <Text as="span" color="orange.400">
          <FaCirclePause style={{ display: 'inline' }} />
        </Text>
      );
    default:
      return (
        <Text as="span" color="gray.400">
          <FaCircle style={{ display: 'inline' }} />
        </Text>
      );
  }
};

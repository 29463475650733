import { create } from 'zustand';

import { makeListener } from 'BootQuery/Assets/js/socket-event-listener';

import { updatePresence } from './Api/own-presence';
import { SetPresence, UserPresence } from './types';
import { getUserModuleData } from './user-module-data';

export interface IUserStore {
  init: () => Promise<void>;
  username: string | null;
  presence: UserPresence | null;
  updatePresence: (changes: SetPresence) => Promise<void>;
  initialized: boolean;
}

export const userStore = create<IUserStore>((set, get) => ({
  init: async () => {
    const { username, presence } = getUserModuleData();
    const { userID } = window.Bootstrap.bootquery.session;

    userEventListener().subscribeWebSocket(
      `user/presence/${userID}`,
      (presence: UserPresence) => {
        set({ presence });
      }
    );

    set({ username, presence, initialized: true });
  },
  username: null,
  presence: null,
  updatePresence: async (changes) => {
    const presence = await updatePresence(changes);
    set({ presence });
  },
  initialized: false,
}));

const userEventListener = makeListener('userStore');

import { Api } from './api';

interface IdentityTokenResponse {
  token: string;
}

export async function getIdentityToken(): Promise<string> {
  // TODO cache and check if valid
  return fetchIdentityToken();
}

export async function fetchIdentityToken(): Promise<string> {
  const { data } = await Api.get<IdentityTokenResponse>('/api/me/identityToken');

  return data.token;
}

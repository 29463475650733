import { ReactElement, useCallback } from 'react';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaAdjust, FaMoon, FaSun } from 'react-icons/fa';

import { IconButton } from '@bq/components/IconButton';
import { colorModeStore } from 'BootQuery/Assets/js/store/color-mode';

const modes = ['system', 'light', 'dark'] as const;

export const ColorModeMenu = (): ReactElement => {
  const { colorModeSetting } = colorModeStore();

  return (
    <Menu isLazy>
      <MenuButton
        as={IconButton}
        label={useColorModeTitle(colorModeSetting)}
        icon={<ColorModeIcon colorMode={colorModeSetting} />}
      />
      <MenuList>
        {modes.map((mode) => (
          <ColorModeItem key={mode} colorMode={mode} />
        ))}
      </MenuList>
    </Menu>
  );
};

const ColorModeItem = ({ colorMode }: ColorModeProps): ReactElement => {
  const onSelect = useCallback(() => {
    colorModeStore.getState().setColorModeSetting(colorMode);
  }, [colorMode]);

  return (
    <MenuItem onClick={onSelect}>
      <ColorModeIcon colorMode={colorMode} />
      &nbsp; {useColorModeTitle(colorMode)}
    </MenuItem>
  );
};

type ColorMode = 'light' | 'dark' | 'system';
interface ColorModeProps {
  colorMode: ColorMode;
}

const ColorModeIcon = ({ colorMode }: ColorModeProps): ReactElement => {
  switch (colorMode) {
    case 'light':
      return <FaSun />;
    case 'dark':
      return <FaMoon />;
    default:
      return <FaAdjust />;
  }
};

const useColorModeTitle = (colorMode: ColorMode): string => {
  const { t } = useTranslation('User');

  switch (colorMode) {
    case 'light':
      return t('global:color_mode_light');
    case 'dark':
      return t('global:color_mode_dark');
    default:
      return t('global:color_mode_auto');
  }
};

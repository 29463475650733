import { ReactElement } from 'react';
import { Box, Menu, MenuButton, MenuDivider, MenuList } from '@chakra-ui/react';

import { usePresenceTypes } from '../Api/presence-types';
import { userStore } from '../user-store';
import { CurrentPresence } from './CurrentPresence';
import { NoteInput } from './NoteInput';
import { PresenceTypeItem } from './PresenceTypeItem';

export const PresenceMenu = (): ReactElement => {
  const { presence } = userStore();
  const presenceTypes = usePresenceTypes();

  return (
    <Menu isLazy>
      <MenuButton as={CurrentPresence} width="12rem" presence={presence} />
      <MenuList>
        {presenceTypes?.data?.map((presence) => (
          <PresenceTypeItem key={presence.ID} {...presence} />
        ))}
        <MenuDivider />
        <Box px="2">
          <NoteInput />
        </Box>
      </MenuList>
    </Menu>
  );
};
